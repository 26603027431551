import { useFormik } from "formik";
import * as Yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/autotravelbot.png"
import { httpCall } from "./_services/httpCall";
import { ErrorNotification } from "./_HelperComponents/Notification";



const Login = () => {
    const navigate = useNavigate()




    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
            password: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => {
            console.log("submit", values)
            // localStorage.setItem("AuthToken", "token");
            // navigate("/Layoat");
            var response = await httpCall(
                "application/json",
                values,
                `users/login`,
                "POST",
                false
            );
            console.log("respons", response)
            if (response?.isSuccess) {
                localStorage.setItem("AuthToken", response.data.token);
                localStorage.setItem("AuthRoleId", response.data.roleId);
                navigate("/Layoat");
            } else {

                return ErrorNotification(response.error);
            }

        },
    });
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="main-panel">
                    <div className="content-wrapper d-flex align-items-center auth px-0">
                        <div className="row w-100 mx-0">
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div className="brand-logo text-center">
                                        <img src={logo} alt="logo" />
                                    </div>
                                    <h4>Hello! let's get started</h4>
                                    <h6 className="font-weight-light">Sign in to continue.</h6>
                                    <form className="pt-3" onSubmit={formik.handleSubmit}>
                                        <div className="form-group">
                                            <input type="text" name="email" onChange={formik.handleChange} className="form-control form-control-lg" placeholder="Email" />
                                            {formik.errors.email && formik.touched.email ? <div className="text-danger">{formik.errors.email}</div> : null}

                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="password" onChange={formik.handleChange} className="form-control form-control-lg" placeholder="Password" />
                                            {formik.errors.password && formik.touched.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                                        </div>
                                        <div className="mt-3">
                                            <button type="submit" disabled={formik.isSubmitting} className="w-100 btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">SIGN IN</button>

                                            {/* <a className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={OnSignIn}>SIGN IN</a> */}
                                        </div>
                                        {/* <div className="my-3 d-flex justify-content-between align-items-center">

                                            <div className="auth-link text-black pointer">Forgot password?</div>
                                        </div>

                                        <div className="text-center mt-4 font-weight-light">
                                            Don't have an account? <span className="text-primary pointer"><Link to="/Register">Create</Link></span>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
