import logo from "../assets/images/autotravelbot.png"
import logoMini from "../assets/images/autotravelbot.png"
import SignalLogo from "../assets/images/signallogo.png"
import profileImg from "../assets/images/faces/face28.png"
import { useEffect, useRef, useState } from "react"
import { Routes, Route, useNavigate, Router } from "react-router-dom"
import PrivateRoute from "./_services/PrivateRouteComponent"
import Dashboard from "./pages/Dashboard"
import EventsPage from "./pages/EventsPage"
import { Link } from "react-router-dom"
import { httpCall } from "./_services/httpCall"
import { ErrorNotification } from "./_HelperComponents/Notification"
const Layoat = () => {
    const navigate = useNavigate()
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const [OpenSetting, setOpenSetting] = useState(false)
    const [ProfileName, setProfileName] = useState("")


    useEffect(() => {
        GetName()
        if (window.location.pathname === "/Layoat") {
            navigate("/Layoat/Dashboard");

        }
    }, [])
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    //   alert("You clicked outside of me!");
                    setOpenSetting(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const GetName = async () => {
        var response = await httpCall(
            "application/json",
            "",
            `users/profile`,
            "GET",
            true
        );
        console.log("respons GetName", response)
        if (response?.isSuccess) {
            setProfileName(`${response.data.name}(${response.data.company.name})`)
        } else {

            return ErrorNotification(response.error);
        }
    }
    const OpenSettingFn = () => {
        setOpenSetting(!OpenSetting)
    }
    const OnSignOut = () => {
        localStorage.removeItem("AuthToken")
        localStorage.removeItem("AuthRoleId")
        navigate("/");
    }
    return (
        <div className="container-scroller main-panel">

            {/* <!-- partial:partials/_horizontal-navbar.html --> */}
            <div className="horizontal-menu">
                <nav className="navbar top-navbar col-lg-12 col-12 p-0">
                    <div className="container-fluid">
                        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">

                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                                <div className="navbar-brand brand-logo text-center" ><img src={logo} alt="logo" /></div>
                                <div className="navbar-brand brand-logo-mini" ><img src={logoMini} alt="logo" /></div>
                            </div>
                            <ul className="navbar-nav navbar-nav-right ">

                                <li className="nav-item nav-profile dropdown d-flex flex-column justify-content-center">

                                    <div className="nav-link dropdown-toggle pointer" onClick={OpenSettingFn}>
                                        <i className="mdi mdi-account text-primary "></i>
                                        <span className="nav-profile-name">{ProfileName}</span>
                                        {/* <img src={profileImg} alt="profile" /> */}
                                    </div>
                                    <div className={`dropdown-menu dropdown-menu-right navbar-dropdown ${OpenSetting ? "d-block" : "d-none"}`}>

                                        {/* <a className="dropdown-item">
                                            <i className="mdi mdi-settings text-primary"></i>
                                            Settings
                                        </a> */}
                                        <div ref={wrapperRef} className="dropdown-item" onClick={OnSignOut}>
                                            <i className="mdi mdi-logout text-primary"></i>
                                            Logout
                                        </div>
                                    </div>
                                    <div className="text-black">For Support Contact Through
                                        <a href="https://signal.me/#p/+919791016079" target="_blank">
                                            <img src={SignalLogo} className="signalImg" alt="Signal" />
                                        </a>
                                    </div>
                                </li>

                            </ul>

                        </div>
                    </div>
                </nav>
                <nav className="bottom-navbar">
                    <div className="container">
                        <ul className="nav page-navigation">
                            <li className={`nav-item pointer ${window.location.pathname == "/Layoat/Dashboard" || window.location.pathname == "Dashboard/EventsPage" ? "active" : ""}`}>

                                <Link className="nav-link text-decoration-none" to={"Dashboard"}>
                                    <i className="mdi mdi-file-document-box menu-icon"></i>
                                    <span className="menu-title">Dashboard</span>

                                </Link>
                            </li>

                        </ul>
                    </div>
                </nav>
            </div>

            <div className="mx-3 mt-4 ">
                {/* <Dashboard /> */}
                <Routes>

                    <Route path="Dashboard" element={<PrivateRoute component={Dashboard} />} />
                    <Route path="Dashboard/EventsPage/:RequestId" element={<PrivateRoute component={EventsPage} />} />



                </Routes>
            </div>

        </div>

    )

}
export default Layoat
