import React from "react";
import { Store } from "react-notifications-component";

export const SuccessNotification = (message ) => {
  Store.addNotification({
    title: <i className="mdi mdi-check"></i>,
    message: message,
    type: "success",
    insert: "top",
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
export const ErrorNotification = (message  ) => {
  Store.addNotification({
    title: <i className="mdi mdi-close"></i>,
    message: message,
    type:"danger" ,
    insert: "top",
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
export const InfoNotification = (message  ) => {
  Store.addNotification({
    title: <i className="mdi mdi-information"></i>,
    message: message,
    type: "info",
    insert: "top",
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
export const WarningNotification = ( message  ) => {
  Store.addNotification({
    title: <i className="mdi mdi-exclamation"></i>,
    message: message,
    type: "warning",
    insert: "top",
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
export const DefaultNotification = (title  , message  ) => {
  Store.addNotification({
    title: title,
    message: message,
    type: "default",
    insert: "top",
    container: "top-left",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
