import axios from "axios";
// import { ErrorNotification } from "../_HelperComponents/Notification";
import { Constants } from "../_Helpers/Constans";

export async function httpCall(
  contentType,
  data,
  url,
  Method,
  isPrivate = false
) {
  var token;
  if (isPrivate) {
    var user = localStorage.getItem("AuthToken")
    token = user;
    // console.log(token)
  }
  try {
    console.log("http")
    var response = await axios({
      url: `${Constants.Url}${url}`,
      method: Method,
      data: data,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // console.log(error)
    if (error.response?.status === 401) {
      if (typeof window !== 'undefined') {
        console.log("401")
        localStorage.removeItem("AuthToken")
        window.location.pathname = "/";
      }
    }
    return error.response?.data;
  }
}


