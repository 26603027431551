import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorNotification } from "../_HelperComponents/Notification";
import { formatDate, formatDateTime } from "../_services/formatDate";
import { httpCall } from "../_services/httpCall";
import { Constants } from "../_Helpers/Constans";


const EventsPage = (props) => {

    let { RequestId } = useParams();
    const [EventsList, setEventsList] = useState([])
    const [Loding, setLoding] = useState(false)
    useEffect(() => {
        console.log("id", RequestId)
        GetEventsList()
    }, [])
    const GetEventsList = async () => {
        setLoding(true)
        var response = await httpCall(
            "application/json",
            "",
            `request/requesteventlist/${RequestId}`,
            "GET",
            true
        );
        console.log("respons GetEventsList", response)
        if (response?.isSuccess) {
            var list = []

            response.data.map((item, index) => {
                list.push(
                    <tr key={item.uniqueId}>
                        <td>{index + 1}</td>
                        {/* <td>{item.uniqueId}</td> */}
                        <td>{item.request.url}</td>
                        <td>{item.request.username}</td>
                        <td className="Blur-text">{item.request.password}</td>
                        <td>{formatDate(item.request.currentAppointment)}</td>
                        <td>{item.request.priority == 0 ? "Normal" : "High"}</td>
                        <td>{formatDate(item.request.createdDate)}</td>
                        <td style={{ color: item.request.statusColor }}>{item.request.statusText}</td>
                        <td>{formatDateTime(item.createdDate)}</td>
                        <td>{item.event}</td>
                    </tr>
                )
            })
            setEventsList(list)
            setLoding(false)
        } else {

            return ErrorNotification(response.error);
        }
    }
    return (
        <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-start">
                        <h4 className="card-title px-3">Events</h4>
                        <i className={`mdi mdi-cached ${Loding?"mdi-spin":""}  pointer `} onClick={GetEventsList} />
                        </div>
                        <a  href={`${Constants.Url}request/requesteventlistcsv/${RequestId}`} className="btn btn-success text-white">Export</a>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {/* <th>Event ID</th> */}
                                    <th>url</th>
                                    <th>User Name</th>
                                    <th>Password</th>
                                    <th>Current Appointment</th>
                                    <th>Priority</th>
                                    <th>Request Date</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Event</th>

                                </tr>
                            </thead>
                            <tbody>
                                {EventsList.length > 0 ? EventsList : <th colSpan={4} className="text-center" >No Data</th>}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EventsPage