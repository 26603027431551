import { useFormik } from "formik"
import { useEffect, useState } from "react"
import DatePicker from "react-date-picker";
import Modal from "react-responsive-modal"
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import { ErrorNotification, SuccessNotification } from "../_HelperComponents/Notification";
import { formatDate, formatDateTime } from "../_services/formatDate";
import { httpCall } from "../_services/httpCall";

const Dashboard = () => {
    const [OpenNewModal, setOpenNewModal] = useState(false)
    const [OpenSlotsModal, setOpenSlotsModal] = useState(false)
    const [RequestList, setRequestList] = useState([])
    const [SlotsList, setSlotsList] = useState([])
    const [Loding, setLoding] = useState(false)
    const [NoApointment, setNoApointment] = useState(false)

    useEffect(() => {
        GetRequestList()
    }, [])

    const GetRequestList = async () => {
        setLoding(true)
        var response = await httpCall(
            "application/json",
            "",
            `request/requestlist`,
            "GET",
            true
        );
        console.log("respons GetRequestList", response)
        if (response?.isSuccess) {
            var list = []
            response.data.map((item, index) => {

                list.push(
                    <tr key={item.uniqueId}>
                        <td>{index + 1}</td>
                        {/* <td>{item.uniqueId}</td> */}
                        <td>{item.url}</td>
                        <td>{item.username}</td>
                        <td className="Blur-text">{item.password}</td>
                        <td>{item.noAppointment ? "-" : formatDate(item.currentAppointment)}</td>
                        {/* <td>{formatDate(item.maxAppointment)}</td> */}
                        <td>{item.priority == 0 ? "Normal" : "High"}</td>
                        <td>{formatDate(item.createdDate)}</td>
                        <td className="text-center">{item.noAppointment ? <i className="mdi mdi-close-circle-outline mdi-36px text-danger" /> : <i className=" mdi-36px mdi mdi-checkbox-marked-circle-outline text-success" />}</td>
                        <td style={{ color: item.statusColor }}>{item.statusText}</td>
                        <td >
                            <div className="btn btn-info"><Link to={`EventsPage/${item.uniqueId}`} className="text-decoration-none text-reset">Events</Link></div>
                            {item.noAppointment ? <div className="btn btn-inverse-info mx-2" onClick={() => GetSlots(item.uniqueId)}>Slots</div> : null}
                        </td>
                    </tr>
                )
            })
            setRequestList(list)
            setLoding(false)
        } else {

            return ErrorNotification(response.error);
        }
    }

    const GetSlots = async (e) => {
        setOpenSlotsModal(true)
        console.log("id slot ", e)
        var response = await httpCall(
            "application/json",
            "",
            `request/RequestSlotsList/${e}`,
            "GET",
            true
        );
        console.log("respons GetRequestList", response)
        if (response?.isSuccess) {
            var list = []
            response.data.map((item, index) => {

                list.push(
                    <tr key={item.uniqueId}>
                        <td>{index + 1}</td>
                        <td>{formatDateTime(item.createdDate)}</td>
                        <td>{item.center}</td>
                        <td>{item.category}</td>
                        <td>{item.result}</td>
                       
                    </tr>
                )
            })
            setSlotsList(list)
        } else {

            return ErrorNotification(response.error);
        }
    }

    const HandleApointment = (e) => {
        setNoApointment(e.target.checked)
        formik.handleChange(e)
        if (e.target.checked) {
            formik.setFieldValue("CurrentAppointment", new Date())

        } else {
            formik.setFieldValue("CurrentAppointment", "")
        }
    }
    const OnNew = () => {
        setOpenNewModal(true)
        setNoApointment(false)
    }
    const formik = useFormik({
        initialValues: {
            Username: "",
            Url: "",
            Password: "",
            CurrentAppointment: "",
            Priority: "",
            NoApointment: false
        },
        validationSchema: Yup.object().shape({
            Username: Yup.string().required('Required'),
            Url: Yup.string().required('Required'),
            Password: Yup.string().required('Required'),
            CurrentAppointment: Yup.string().required('Required'),
            Priority: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => {
            console.log("submit", values)

            var response = await httpCall(
                "application/json",
                values,
                `request/createrequest`,
                "POST",
                true
            );
            console.log("respons", response)
            if (response?.isSuccess) {
                setOpenNewModal(false)
                formik.setFieldValue("CurrentAppointment", "")
                GetRequestList()
                return SuccessNotification("New Request Successfully Add")
            } else {
                return ErrorNotification(response.error);
            }

        },
    });
    return (
        <>

            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="p-3">
                        <div className="d-flex justify-content-between">
                            <h4 className="card-title">
                                <span className="px-3">Requests</span>
                                <i className={`mdi mdi-cached ${Loding ? "mdi-spin" : ""}  pointer `} onClick={GetRequestList} />
                            </h4>
                            <div className="btn btn-primary" onClick={OnNew}>NEW Request</div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        {/* <th className="w-170">Request ID</th> */}
                                        <th>url</th>
                                        <th>User Name</th>
                                        <th>Password</th>
                                        <th>Current Appointment</th>
                                        {/* <th>Max Appointment</th> */}
                                        <th>Priority</th>
                                        <th>Request Date</th>
                                        <th>Have Appointment</th>
                                        <th>Status</th>
                                        <th> </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {RequestList.length > 0 ? RequestList : <th colSpan={10} className="text-center" >No Data</th>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={OpenSlotsModal} onClose={() => setOpenSlotsModal(false)} center>
                <div className="table-responsive mt-4">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Center</th>
                                <th>Category</th>
                                <th>Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {SlotsList.length > 0 ? SlotsList : <th colSpan={10} className="text-center" >No Data</th>}

                        </tbody>
                    </table>
                </div>
            </Modal>
            <Modal open={OpenNewModal} onClose={() => setOpenNewModal(false)} center>
                <div className="px-3">
                    <div className="card-body">
                        <h4 className="card-title">New Request</h4>

                        <form className="forms-sample" onSubmit={formik.handleSubmit}>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Url</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Url" name="Url" onChange={formik.handleChange} />
                                    {formik.errors.Url && formik.touched.Url ? <div className="text-danger">{formik.errors.Url}</div> : null}

                                </div>

                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">UserName</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Username" name="Username" onChange={formik.handleChange} />

                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Password</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="Password" name="Password" onChange={formik.handleChange} />
                                    {formik.errors.Password && formik.touched.Password ? <div className="text-danger">{formik.errors.Password}</div> : null}
                                </div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input mx-0" type="checkbox" name="NoApointment" onChange={(e) => HandleApointment(e)} />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Don't have appointment
                                    <span className="font12"> (want to see available slots only)</span>
                                </label>
                            </div>
                            {NoApointment ?
                                null :
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Current Appointment</label>
                                    <div className="col-sm-9">
                                        <DatePicker minDate={new Date()} className="form-control" name="CurrentAppointment" onChange={(e) => formik.setFieldValue("CurrentAppointment", e)} value={formik.values.CurrentAppointment} />
                                        {formik.errors.CurrentAppointment && formik.touched.CurrentAppointment ? <div className="text-danger">{formik.errors.CurrentAppointment}</div> : null}
                                    </div>
                                </div>
                            }

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Priority</label>
                                <div className="col-sm-9">
                                    <select className="form-control form-control-lg " name="Priority"
                                        onChange={(e) => formik.setFieldValue("Priority", e.target.value)}
                                    >
                                        <option value="">choose</option>
                                        <option value={0}>Normal</option>
                                        <option value={1}>High</option>

                                    </select>
                                    {formik.errors.Priority && formik.touched.Priority ? <div className="text-danger">{formik.errors.Priority}</div> : null}
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary me-2">Submit</button>
                            <div className="btn btn-light" onClick={() => setOpenNewModal(false)}>Cancel</div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Dashboard