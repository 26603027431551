
import './App.css';
import "./assets/css/style.css"
import "./assets/fonts/fontawesome-free/css/all.min.css"
import "../src/assets/fonts/material-design-iconic-font/css/materialdesignicons.min.css"
import 'react-responsive-modal/styles.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-notifications-component/dist/theme.css'
import "./assets/css/custom.css"

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Layoat from "./components/Layoat"
import Login from "./components/Login"
import Register from "./components/Register"
import PrivateRoute from "./components/_services/PrivateRouteComponent"
import Dashboard from './components/pages/Dashboard';
import { ReactNotifications } from 'react-notifications-component';

function App() {
  return (
    <>
      <ReactNotifications />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/Register" element={<Register />} /> */}
          <Route path="/Layoat/*" element={<PrivateRoute component={Layoat} />} >
            {/* <Route  path="Dashboard" element={<PrivateRoute component={Dashboard} />} /> */}

          </Route>

        </Routes>
      </Router>
    </>
  );
}

export default App;
