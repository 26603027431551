import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  // Add your authentication logic here
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    getUser()
}, [])
 const getUser = () => {
        var user=localStorage.getItem("AuthToken")
          if (user) {
              console.log("token")
           
          } else {
            setIsAuthenticated(false)
          }
      };
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" replace />;
};
export default PrivateRoute;






 
